<template>
    <div class="app-indexpage u-rela">
        <launch v-show="launch_show"></launch>
        <div v-show="index_show">
            <div class="banner">
                <div class="logo u-flex">
                    <img
                            class="img"
                            src="../../assets/images/applet/index/icon1.png"
                            alt=""
                    />
                    <span class="u-font-20 white">潢溪溯源</span>
                </div>
                <div class="lay1 u-flex u-row-center u-m-t-35 white">
                    <span class="blod u-rela">好食材</span>不怕公开
                </div>
                <div class="descript letter-2 auto u-flex u-row-center u-m-t-24">
                    看的见溯源匠心 吃得出健康安心
                </div>
                <img
                        class="block auto img1"
                        src="../../assets/images/applet/index/circle.png"
                        alt=""
                />
            </div>
            <div class="wrap u-m-l-30 u-m-r-30">
                <!-- 视频 -->
                <div class="panel videopanel u-rela">
                    <!--                    <video src="../../assets/images/video.mp4" controls></video>-->
                    <img class="index_image" :src="good.img_url">
                    <h2 class="yellow u-font-50 u-text-center u-m-t-28">{{good.name}}</h2>
                    <p class="u-font-22 yellow auto u-rela">
                        {{good.subtitle}}
                    </p>
                    <img
                            class="u-abso img1"
                            src="../../assets/images/applet/index/icon2.png"
                            alt=""
                    />
                    <img
                            class="u-abso img2"
                            src="../../assets/images/applet/index/icon2.png"
                            alt=""
                    />
                </div>
                <!-- 溯源编号 -->
                <div class="panel u-p-40 u-flex u-m-t-40 u-row-between">
                    <div class="u-flex u-flex-col u-col-top">
                        <div class="u-m-b-5 u-flex">
                            <span class="u-font-20 u-tips-color">您查询的溯源码编号</span>
                            <div class="tag1 u-font-14 u-flex u-m-l-16">
                                <span>总赋码</span>
                                <span class="green2">{{good.code_total}}</span>
                                <i class="iconfont u-font-20 green iconxiangshangjiantou"></i>
                            </div>
                        </div>
                        <div class="u-m-b-10 u-flex">
                            <span class="u-font-24 blod u-main-color">{{code}}</span>
                            <span class="bggreen u-font-14 u-p-l-6 u-p-r-6 radius-5 u-m-l-16">
              <i class="white iconfont iconcheck_fill u-font-14 u-m-r-5"></i>
              <i class="white">核验通过</i></span
                            >
                        </div>
                        <span class="u-font-20 u-tips-color">本编码查询次数</span>
                        <span class="u-font-24 blod u-main-color u-m-b-10">{{good.count}}</span>
                        <span class="u-font-20 u-tips-color">产地</span>
                        <span class="u-font-24 blod u-main-color">{{good.place}}</span>
                        <div class=" u-m-t-25 u-flex">
                            <span class="u-font-20 u-tips-color">溯源批次</span>
                            <div class="tag1 u-font-14 u-flex u-m-l-16">
                                <span>本批次</span>
                                <span class="green2">{{good.batch_total}}</span>
                                <i class="iconfont green iconxiangshangjiantou u-font-20"></i>
                            </div>
                        </div>
                        <span class="u-font-24 blod u-main-color">{{good.batch_code}}</span>
                    </div>
                    <div class="u-flex u-flex-col">
                        <img
                                class="ewm"
                                src="../../assets/images/applet/index/ewm.png"
                                alt=""
                        />
                        <!--                        <span class="u-m-t-5 u-light-color">AIRAO&lt;1888&lt;828</span>-->
                    </div>
                </div>
                <!-- 留言 -->
                <router-link :to="{path:'/applet/message',query:{code:code}}"
                             class="msgbox u-m-t-50 u-flex u-font-22 white u-p-l-34 u-p-r-34">
                    <img class="msg" src="../../assets/images/applet/index/msg.png" alt=""/>
                    <span class="u-flex-1 u-m-l-20">为爱溯源，给你留言啦，快快查看。</span>
                    <i class="iconfont iconxiangxiajiantou-copy u-font-50 white"></i>
                </router-link>
                <!-- tab切换 -->
                <div class="tabnav u-m-t-50 radius-15 u-flex u-font-36">
                    <router-link :to="{path: '/applet/index/traceInfo',query: {code: code,}}" class="u-flex-1 radius-15"
                                 @click="tabcur = 0">
                        <i class="icon icon1"></i>
                        <span>追溯信息</span>
                    </router-link>
                    <router-link :to="{path: '/applet/index/proInfo',query: {code: code,}}" class="u-flex-1 radius-15"
                                 @click="tabcur = 1">
                        <i class="icon icon2"></i>
                        <span>商品介绍</span>
                    </router-link>
                </div>
            </div>
            <div>
                <router-view></router-view>
            </div>
            <FloatBall :text="'首页'"></FloatBall>
        </div>
    </div>





</template>
<script>
    import launch from "./launch";
    import FloatBall from "../../components/ball.vue";
    export default {
        components: {
            launch,
            FloatBall
        },
        name: "",
        created() {
            this.code = this.$route.query.code;
            if (window.name == "isReload") {
                this.type = 2;
            }else{
                //加载中页面
            }
            this.$axios.get('getGoodDetail', {
                params: {
                    code: this.code,
                    type: this.type,
                }
            }).then(res => {
                //请求的数据存储在res.data 中
                if (res.data.status == 200) {
                    this.good = res.data.data;
                    if (window.name == "") {
                        this.launch_show = true;
                        this.index_show = false;
                        window.name = "isReload";// 在首次进入页面时我们可以给window.name设置一个固定值
                        setTimeout(() => {
                            this.launch_show = false;
                            this.index_show = true;
                        }, 3000);
                    } else if (window.name == "isReload") {
                        this.launch_show = false;
                        this.index_show = true;
                    }
                }else{
                    this.index_show = false;
                    this.$message.error('错误的溯源码！');
                }
                // console.log(this.good);
            }).catch(function (error) {
                console.log(error);
            });
        },
        data() {
            return {
                code: '未找到溯源码',
                launch_show: false,
                index_show: false,
                tabcur: 1,
                type: 1,
                loadimg: '',
                good: {
                    'code': '',
                    'name': '',
                    'packing': '',
                    'place': '',
                    'specs': '',
                    'storage_method': '',
                    'subtitle': '',
                    'img_url': '',
                    'img_type': '',
                    'loading_img': '',
                }
            };
        },
        mounted() {

        },
        methods: {},
    };
</script>
<style lang="scss">
    @import "../../assets/css/applet.scss";
</style>
